<template>
  <a-card class="card rent" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :defaultQueryParam="defaultQueryParams"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getCumsMetaCompanyPage"
      :deleteFun="deleteCumsMetaInfo"
      :createFun="saveCumsMetaInfo"
      :updateFun="updateCumsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getCumsMetaCompanyPage, saveCumsMetaInfo, updateCumsMetaInfo, deleteCumsMetaInfo } from '@/api/cums'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      tenantOps: [],
      queryFields: [
        { label: '报关行名称', name: 'name', fieldType: '', queryType: '%' }
      ],
      defaultQueryParams: {
        type: 4
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 80,
          align: 'center'
        },
        {
          title: '报关行名称',
          dataIndex: 'name',
          width: 150
        },
        {
          title: '联系人',
          dataIndex: 'contacts',
          customRender: (text) => {
            return text && text.map(item => {
              return item.name + ': ' + item.phone
            }).join('、')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请填写报关行名称', trigger: 'blur' }
        ]
      },
      modelTitle: '报关行管理',
      formFields: [
        {
          label: '报关行名称', name: 'name', type: 'default', span: 24
        },
        {
          label: '联系人信息',
          name: 'contacts',
          type: 'editable',
          span: 24,
          extra: {
            columns: [
              {
                title: '联系人名称',
                dataIndex: 'name',
                width: 120
              },
              {
                title: '联系人电话',
                dataIndex: 'phone',
                width: 120
              }
            ],
            data: []
          }
        },
        {
          label: '', name: 'type', type: 'hidden', defaultValue: 4
        }
      ],
      moduleName: 'cums_company'
    }
  },
  methods: {
    getCumsMetaCompanyPage,
    saveCumsMetaInfo,
    updateCumsMetaInfo,
    deleteCumsMetaInfo
  }
}
</script>
